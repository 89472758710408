import React from 'react'
import { VehicleFile } from '../../shared/shared.models'
import { Row, Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { CloseOutlined } from '@material-ui/icons'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import { getFilePlugin } from '@react-pdf-viewer/get-file'
import '@react-pdf-viewer/core/lib/styles/index.css'

interface FullSizeGalleryProps {
    file: VehicleFile
    closeOverlay: () => void
}

export const FullSizeFilesGallery = ({
    file,
    closeOverlay,
}: FullSizeGalleryProps) => {
    const isImage = /\.(jpeg|jpg|gif|png)$/i.test(file.url)
    const isPDF = /\.pdf$/i.test(file.url)

    const getFilePluginInstance = getFilePlugin()
    const { DownloadButton } = getFilePluginInstance

    const onKeyboardClick = React.useCallback(
        (evt: KeyboardEvent) => {
            if (evt.keyCode === 27) {
                // Esc key
                closeOverlay()
            }
        },
        [file, closeOverlay]
    )

    React.useEffect(() => {
        document.addEventListener('keydown', onKeyboardClick)

        return () => document.removeEventListener('keydown', onKeyboardClick)
    }, [onKeyboardClick])

    return isImage ? (
        <Overlay onClick={closeOverlay}>
            <Row
                style={{
                    marginBottom: 40,
                    width: '100%',
                    paddingRight: 100,
                    justifyContent: 'flex-end',
                }}
            >
                <CloseOutlined style={{ color: 'white', fontSize: 40 }} />
            </Row>

            <FullScreenImage
                onClick={e => {
                    e.stopPropagation()
                }}
                src={file.url}
                alt="full screen img"
            />
        </Overlay>
    ) : isPDF ? (
        <Overlay backgroundColor={'#ffcc33'}>
            <Row
                style={{
                    marginTop: 50,
                    width: '100%',
                    paddingRight: 100,
                    justifyContent: 'space-around',
                    backgroundColor: '#ffcc33',
                }}
            >
                <Download>
                    <DownloadButton />
                </Download>
                <CloseOutlined
                    style={{ color: 'white', fontSize: 40, cursor: 'pointer' }}
                    onClick={closeOverlay}
                />
            </Row>
            <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}
            >
                <Viewer fileUrl={file.url} plugins={[getFilePluginInstance]} />
            </Worker>
        </Overlay>
    ) : null
}

const FullScreenImage = styled('img')`
    height: 60%;
    width: auto;
`

const Overlay = styled(Column as any)<{ backgroundColor?: string }>(
    props => `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props.backgroundColor ?? 'rgba(0, 0, 0, 0.95)'} ;
    align-items: center;
    justify-content: center;
    z-index: 20;
`
)

const Download = styled('div')`
    border: 1px;
    background-color: white;
`
