import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminApi } from '../admin.api'
import { IStoreInterface } from '../../configs/store.config'
import { Contact, LoggedUser } from '../../shared/shared.models'
import { Column, PageTitle, Row } from '../../shared/shared.styled'
import { useAlert } from 'react-alert'
import { AdminActions } from '../admin.store'
import {
    Table,
    ColumnProps,
} from '../../shared/components/table/table.component'
import Loader from 'react-spinners/DotLoader'
import { Typography, Colors } from '../../configs/styled.config'
import styled from 'styled-components'
import { useFilter } from '../../shared/hooks/use-filter.hook'
import TranslationUtils from '../../shared/utils/translations.utils'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import ContactSearch from '../components/contacts-search.component'
import ActionButton from '../../shared/components/action-button.component'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { AdminUtils } from '../admin.utils'
import { RoleEnum, StatusEnum } from '../../shared/shared.enums'
import { ContactAnalytics } from '../components/contact-analytics.component'
import { ContactFilters } from '../components/contacts-search.component'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import PdfGeneratorButton from '../../shared/components/button2.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
// import { Tooltip } from 'react-tooltip'

export const ContactsPage = () => {
    // Hooks initialization
    const {
        filters,
        updateContactFilter,
        clearAllFilters,
        clearFilter,
    } = useFilter()
    const [isFetchingContacts, setIsFetchingContacts] = React.useState(false)
    const dispatch = useDispatch()

    const alert = useAlert()
    const company = useSelector(
        (state: IStoreInterface) => state.adminReducer.company
    )

    const isAdmin = useIsAdmin()

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.toString() === '') {
            updateContactFilter(ContactFilters.STATUS, StatusEnum.OPEN)
        }
    }, [updateContactFilter])

    // Redux state
    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )
    // Local state
    const [allContacts, setAllContacts] = React.useState<Contact[]>([])
    const [visibleContacts, setVisibleContacts] = React.useState<Contact[]>([])

    const [isLoading, setIsLoading] = React.useState(true)

    const navigateToCreateContact = () => {
        navigate(RoutesEnum.CREATE_CONTACT)
    }

    // Effect to fetch the list of contacts
    React.useEffect(() => {
        setIsFetchingContacts(true)
        AdminApi.methods
            .getContacts(loggedUser.companyUuid)
            .finally(() => {
                setIsFetchingContacts(false)
                setIsLoading(false)
            })
            .then(
                res => {
                    setVisibleContacts(res.data.contacts)
                    setAllContacts(res.data.contacts)
                    dispatch(
                        AdminActions.methods.getContactsSuccessAction(
                            res.data.contacts
                        )
                    )
                },
                e => {
                    alert.error('Error fetching the contacts')
                }
            )
    }, [alert, dispatch, loggedUser.companyUuid])

    React.useEffect(() => {
        const newVisibleContacts = AdminUtils.filterAllContacts(
            filters,
            allContacts
        )
        setVisibleContacts(newVisibleContacts)
    }, [allContacts, filters])

    const getColumns = (): ColumnProps[] => {
        if (
            loggedUser.role === RoleEnum.ADMIN ||
            loggedUser.role === RoleEnum.MANAGER
        ) {
            return [
                {
                    key: 'date',
                    label: 'Data',
                    ratio: 2 / 10,
                    renderFunction: contact => contact.date,
                    onClick: onHeaderClick,
                },
                {
                    key: 'name',
                    ratio: 2 / 10,
                    label: 'Nome',
                    renderFunction: contact => contact.name,
                    onClick: onHeaderClick,
                },
                {
                    key: 'status',
                    label: 'Situação',
                    ratio: 1 / 10,
                    renderFunction: contact =>
                        TranslationUtils.getContactStatusTranslation(
                            contact.status
                        ),
                    onClick: onHeaderClick,
                },
                {
                    key: 'phone',
                    label: 'Nº Telemóvel',
                    ratio: 1 / 10,
                    renderFunction: contact => contact.phone,
                },
                {
                    key: 'seller',
                    label: 'Vendedor',
                    ratio: 2 / 10,
                    renderFunction: contact => contact.contactUserName,
                    onClick: onHeaderClick,
                },
                {
                    key: 'iteractions',
                    label: 'Última iteração',
                    ratio: 4 / 10,
                    renderFunction: contact =>
                        contact.iteractions?.length > 0 ? (
                            <>
                                <Tooltip
                                    title={
                                        contact.iteractions[
                                            contact.iteractions.length - 1
                                        ].description
                                    }
                                >
                                    <span
                                        style={{
                                            color: '#44566c',
                                        }}
                                    >
                                        {
                                            contact.iteractions[
                                                contact.iteractions.length - 1
                                            ].description
                                        }
                                    </span>
                                </Tooltip>
                            </>
                        ) : (
                            <span style={{ color: '#db8835' }}>
                                Sem iterações
                            </span>
                        ),
                },
                {
                    key: 'iteractions',
                    label: 'Data da iteração',
                    ratio: 1 / 10,
                    renderFunction: contact =>
                        contact.iteractions?.length > 0 ? (
                            <>
                                <span
                                    style={{
                                        color: '#44566c',
                                    }}
                                >
                                    {moment(
                                        contact.iteractions[
                                            contact.iteractions.length - 1
                                        ].updatedAt
                                    ).format('DD/MM/YYYY')}
                                </span>
                            </>
                        ) : (
                            <span style={{ color: '#db8835' }}>
                                Sem iterações
                            </span>
                        ),
                },
            ]
        } else {
            return [
                {
                    key: 'date',
                    label: 'Data',
                    ratio: 2 / 10,
                    renderFunction: contact => contact.date,
                    onClick: onHeaderClick,
                },
                {
                    key: 'name',
                    ratio: 2 / 10,
                    label: 'Nome',
                    renderFunction: contact => contact.name,
                    onClick: onHeaderClick,
                },
                {
                    key: 'status',
                    label: 'Situação',
                    ratio: 1 / 10,
                    renderFunction: contact =>
                        TranslationUtils.getContactStatusTranslation(
                            contact.status
                        ),
                    onClick: onHeaderClick,
                },
                {
                    key: 'phone',
                    label: 'Nº Telemóvel',
                    ratio: 1 / 10,
                    renderFunction: contact => contact.phone,
                },
                {
                    key: 'iteractions',
                    label: 'Última iteração',
                    ratio: 4 / 10,
                    renderFunction: contact =>
                        contact.iteractions?.length > 0 ? (
                            <Tooltip
                                title={
                                    contact.iteractions[
                                        contact.iteractions.length - 1
                                    ].description
                                }
                            >
                                <span
                                    style={{
                                        color: '#44566c',
                                    }}
                                >
                                    {
                                        contact.iteractions[
                                            contact.iteractions.length - 1
                                        ].description
                                    }
                                </span>
                            </Tooltip>
                        ) : (
                            <span style={{ color: '#db8835' }}>
                                Sem iterações
                            </span>
                        ),
                },
                {
                    key: 'iteractions',
                    label: 'Data da iteração',
                    ratio: 1 / 10,
                    renderFunction: contact =>
                        contact.iteractions?.length > 0 ? (
                            <>
                                <span
                                    style={{
                                        color: '#44566c',
                                    }}
                                >
                                    {moment(
                                        contact.iteractions[
                                            contact.iteractions.length - 1
                                        ].updatedAt
                                    ).format('DD/MM/YYYY')}
                                </span>
                            </>
                        ) : (
                            <span style={{ color: '#db8835' }}>
                                Sem iterações
                            </span>
                        ),
                },
            ]
        }
    }

    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedContacts = SortUtils.sortContacts(
            visibleContacts,
            header,
            order
        )
        setVisibleContacts(sortedContacts)
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar os seus contatos...</LoadingText>
            </Column>
        )
    }

    const editContact = (contact: Contact) => {
        navigate(RoutesEnum.CONTACT_PAGE.replace(':contactId', contact.uuid))
    }

    const downloadCSVContacts = async (
        companyUuid: string,
        companyName: string
    ) =>
        new Promise<Blob>(async () => {
            try {
                const response = await AdminApi.methods.getCSVContacts(
                    companyUuid
                )

                alert.success('O CSV será transferido automaticamente')
                // Create a link element to download the PDF
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(response.data)

                link.download = `Lista de Contatos - ${companyName}`

                dispatch(AdminActions.methods.getCSVContacts(response.data))

                // Append the link to the document and trigger the click event
                document.body.appendChild(link)
                link.click()

                setTimeout(() => {
                    document.body.removeChild(link)
                }, 1000)
            } catch (error) {
                console.error('Error generating CSV:', error)
            }
        })

    return (
        <>
            <Row style={{ justifyContent: 'space-between' }}>
                <PageTitle>Contatos</PageTitle>
                {isAdmin && (
                    <PdfGeneratorButton
                        icon={'/icons/csv.png'}
                        action={() =>
                            downloadCSVContacts(
                                company ? company.uuid : '',
                                company ? company.name : ''
                            )
                        }
                        title={''}
                    />
                )}
            </Row>
            <ContactSearch
                clearAllFilters={clearAllFilters}
                clearFilter={clearFilter}
                updateFilter={updateContactFilter}
                filters={filters}
            />
            <ContactAnalytics
                allContacts={allContacts}
                visibleContacts={visibleContacts}
            />
            <Table<Contact>
                isLoadingData={isFetchingContacts}
                rowStyles={value =>
                    value.status === StatusEnum.SOLD
                        ? { backgroundColor: '#B3E1A5' }
                        : value.status === StatusEnum.OPEN
                        ? { backgroundColor: '#fcdede' }
                        : value.status === StatusEnum.CLOSED
                        ? {}
                        : {}
                }
                columns={getColumns()}
                onRowClick={editContact}
                getUrl={value =>
                    RoutesEnum.CONTACT_PAGE.replace(':contactId', value.uuid)
                }
                values={visibleContacts}
                contactsExists={visibleContacts?.length > 0 ? true : false}
            />

            <ActionButton onClick={navigateToCreateContact} />
        </>
    )
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
