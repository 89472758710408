import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { AdminApi } from '../admin.api'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { CreateCompanyRequest } from '../admin.interfaces'
// import ManagementSection from "../components/management-section.component";
import FixedPageTitle from '../components/fixed-page-title.component'
// import { CostsSection } from "../components/costs-section.component";
import { createCompanySchema } from '../validation.schemas'
import { Container } from '../../shared/shared.styled'
import PrimaryButton from '../../shared/components/button.component'
import { useAlert } from 'react-alert'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import CompanyFeatures from '../components/company-features.component'

const CreateCompanyPage = () => {
    // Hooks Initialization
    const dispatch = useDispatch()
    //const isSuperAdmin = useIsSuperAdmin()
    const alert = useAlert()

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    hideConfirmationModal()
                    window.history.back()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )

    /**
     * Helper function to get the initial formikValues
     * @param company Company | undefined
     */
    const getFormikInitialValues = () => {
        return {
            name: '',
            color: '',
            logo: undefined,
        }
    }

    /**
     * Calls the API endpoint to save a stand, and handles the loading/error states
     * of the form
     * @param values CreateStandRequest
     * @param actions FormikHelpers<CreateStandRequest>
     */
    const addCompany = async (
        values: CreateCompanyRequest,
        actions: FormikHelpers<CreateCompanyRequest>
    ) => {
        try {
            actions.setSubmitting(true)
            const res = await AdminApi.methods.createCompany(values)
            dispatch(
                AdminActions.methods.createCompanySuccessAction(
                    res.data.company
                )
            )
            navigate(RoutesEnum.COMPANIES_LIST)
        } catch (e) {
            alert.error('Erro ao criar a empresa. Por favor tente mais tarde')
        }
    }

    const checkIfFormIsDirtyAndClose = (isDirty: boolean) => {
        if (isDirty) {
            showConfirmationModal()
        } else {
            window.history.back()
        }
    }

    const renderButtons = (isSubmitting: boolean, isDirty: boolean) => (
        <>
            <PrimaryButton
                label="Cancelar"
                onClick={() => checkIfFormIsDirtyAndClose(isDirty)}
            />
            <PrimaryButton
                isLoading={isSubmitting}
                type="submit"
                label="Criar empresa"
                greenTheme={true}
            />
        </>
    )

    return (
        <Container>
            <Formik<CreateCompanyRequest>
                onSubmit={addCompany}
                initialValues={getFormikInitialValues()}
                validateOnChange={false}
                validationSchema={createCompanySchema()}
                enableReinitialize={true}
            >
                {({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    isSubmitting,
                    handleSubmit,
                    dirty,
                }) => (
                    <Form
                        translate="yes"
                        onSubmit={isSubmitting ? () => null : handleSubmit}
                    >
                        <FixedPageTitle
                            label="Criar uma nova empresa"
                            rightButtons={renderButtons(isSubmitting, dirty)}
                        />
                        {/* TODO: uncomment to create new ones */}
                        {/* <CompanyFeatures
                            isEditing={true}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> */}
                        {/* {vehicle && <MediaSection vehicleId={vehicle.uuid} />} */}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CreateCompanyPage
