import React from 'react'
import { Column, RatioContainer, Row } from '../../shared/shared.styled'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { Typography, Colors } from '../../configs/styled.config'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'
import { VehicleFile } from '../../shared/shared.models'
import FileIcon from '../../assets/icons/file.icon'
import { FullSizeFilesGallery } from './full-screen-gallery-files.component'

interface FilesTableProps {
    files: VehicleFile[]
    editFile: (fileIdx: number) => void
    showDeleteConfirmationModal: () => void
    setSelectedFileToDelete: (value: number) => void
    isEditing?: boolean
}

export const FilesTable = ({
    files,
    editFile,
    showDeleteConfirmationModal,
    setSelectedFileToDelete,
    isEditing = true,
}: FilesTableProps) => {
    const isAdmin = useIsAdmin()
    const isWorkshop = useIsWorkshop()

    const [isFullscreenVisibile, setIsFullscreenVisible] = React.useState(false)
    const [currentFile, setCurrentFile] = React.useState<VehicleFile | null>(
        null
    )

    const resetState = () => {
        setCurrentFile(null)
    }

    return (
        <Column>
            <div style={{ marginBottom: 16 }}>
                <Row>
                    <RatioContainer ratio={1 / 16}>
                        <HeaderText>Ficheiro</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={10 / 16}>
                        <HeaderText>Descrição</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={5 / 16} />
                </Row>
                <Separator />
            </div>
            {files.map((file, idx) => (
                <TableRow key={`file-${idx}`}>
                    <RatioContainer
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        ratio={1 / 16}
                    >
                        <FileClickable
                            onClick={() => {
                                setCurrentFile(file)
                                setIsFullscreenVisible(true)
                            }}
                        >
                            <FileIcon />
                        </FileClickable>
                    </RatioContainer>
                    <EllipsedContainer ratio={10 / 16}>
                        <EllipsedRowText>
                            {file.description ? file.description : '-'}
                        </EllipsedRowText>
                    </EllipsedContainer>
                    <ButtonsContainer ratio={5 / 16}>
                        {(isAdmin || isWorkshop) && isEditing ? (
                            <>
                                <EditIcon onClick={() => editFile(idx)} />
                                <DeleteForeverIcon
                                    onClick={() => {
                                        setSelectedFileToDelete(idx)
                                        showDeleteConfirmationModal()
                                    }}
                                    style={{
                                        fill: Colors['gold-pmauto'],
                                    }}
                                />
                            </>
                        ) : null}
                    </ButtonsContainer>
                </TableRow>
            ))}
            {isFullscreenVisibile && currentFile && (
                <FullSizeFilesGallery
                    file={currentFile}
                    closeOverlay={() => {
                        resetState()
                        setIsFullscreenVisible(false)
                    }}
                />
            )}
        </Column>
    )
}

const HeaderText = styled('span')`
    ${Typography['Body_Text_#1_High_Contrast_Left']}
`

const RowText = styled('div')`
    ${Typography['Body_Text_#2_Regular_Left']}
`

const EllipsedRowText = styled(RowText as any)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 24px;
`

const EllipsedContainer = styled(RatioContainer as any)`
    text-overflow: ellipsis;
    overflow: hidden;
`

const ButtonsContainer = styled(RatioContainer as any)`
    justify-content: flex-end;

    svg {
        width: 20px;
        height: 20px;
        fill: inherit;
        cursor: pointer;
    }

    svg:not(:last-child) {
        margin-right: 16px;
    }
`

const TableRow = styled(Row as any)`
    height: 44px;

    :nth-child(odd) {
        background-color: #fafafa;
    }
`

const Separator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`

const FileClickable = styled('div')`
    width: 32px;
    height: 32px;

    :hover {
        cursor: pointer;
        background-color: #ebebeb;
        border-radius: 32px;
    }
`
