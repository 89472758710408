import React from 'react'
import { AdminApi } from '../admin.api'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { AdminActions } from '../admin.store'
import ActionButton from '../../shared/components/action-button.component'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import VehicleSearch from '../components/vehicle-search.component'
import { AdminUtils } from '../admin.utils'
import { PageTitle, Row } from '../../shared/shared.styled'
import { useFilter } from '../../shared/hooks/use-filter.hook'
import {
    AvailabilityEnum,
    VisibilityStatusEnum,
} from '../../shared/shared.enums'
import { Vehicle } from '../../shared/shared.models'
import {
    Table,
    ColumnProps,
} from '../../shared/components/table/table.component'
import {
    getFormatedComponentFromDays,
    euroFormatter,
    getBrandAndModelComponent,
    renderVehicleImageComponent,
} from '../../shared/shared.utils'
import { useHasStands } from '../../shared/hooks/use-stand-exists.hook'
import { useHasStandsOnly } from '../../shared/hooks/use-just-stand.hook'
import { useHasVehicles } from '../../shared/hooks/use-vehicles-exists.hook'
import { SortUtils, SortOrderEnum } from '../../shared/sort.utils'
import { HeaderEnum } from '../../shared/components/table/table-headers.component'
import { useAlert } from 'react-alert'
import { createSelector } from 'reselect'
import TranslationUtils from '../../shared/utils/translations.utils'
import { LoggedUser } from '../../shared/shared.models'
import PdfGeneratorButton from '../../shared/components/button2.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'

const vehicleSelector = createSelector(
    (state: IStoreInterface) => state.adminReducer.vehicles,
    vehicles =>
        vehicles.filter(
            vehicle =>
                vehicle.availability !== AvailabilityEnum.SOLD &&
                vehicle.availability !== AvailabilityEnum.DELIVERED
        )
)

const AdminDashboardPage = () => {
    // Hooks initialization
    const dispatch = useDispatch()

    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )

    // Redux State
    const vehicles = useSelector(vehicleSelector)
    const company = useSelector(
        (state: IStoreInterface) => state.adminReducer.company
    )

    const standsExists = useHasStands()
    const standExistOnly = useHasStandsOnly()
    const vehiclesExists = useHasVehicles()
    const isAdmin = useIsAdmin()
    const isWorkshop = useIsWorkshop()

    const alert = useAlert()

    const [visibleVehicles, setVisibleVehicles] = React.useState<Vehicle[]>([])

    // Local state
    const [isFetchingVehicles, setIsFetchingVehicles] = React.useState(false)
    const { filters, updateFilter, clearAllFilters, clearFilter } = useFilter()

    React.useEffect(() => {
        setVisibleVehicles(vehicles)
    }, [vehicles])

    // Effects
    React.useEffect(() => {
        const queryParams = '?availability=READY_FOR_SALE,VERIFYING,REPARING'
        setIsFetchingVehicles(true)
        AdminApi.methods
            .getVehiclesCompany(loggedUser.companyUuid, queryParams)
            .finally(() => setIsFetchingVehicles(false))
            .then(
                res => {
                    const { vehicles } = res.data
                    dispatch(
                        AdminActions.methods.getVehiclesSuccessAction(vehicles)
                    )
                },
                e => {
                    alert.show(
                        'Erro ao actualizar os veículos, por favor tente mais tarde',
                        { type: 'error' }
                    )
                }
            )
    }, [dispatch, alert, loggedUser.companyUuid])

    React.useEffect(() => {
        const newVisibleVehicles = AdminUtils.filterAllVehicles(
            filters,
            vehicles
        )
        setVisibleVehicles(newVisibleVehicles)
    }, [filters, vehicles])

    // Functions
    const navigateToCreateVehicle = () => {
        navigate(RoutesEnum.CREATE_VEHICLE)
    }

    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedVehicles = SortUtils.sortVehicles(
            visibleVehicles,
            header,
            order
        )
        setVisibleVehicles(sortedVehicles)
    }

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: 'image',
                ratio: 1 / 10,
                label: '',
                renderFunction: vehicle => renderVehicleImageComponent(vehicle),
            },
            {
                key: HeaderEnum.BRAND,
                ratio: 2 / 10,
                label: 'Marca / Modelo / Versão',
                renderFunction: vehicle => getBrandAndModelComponent(vehicle),
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnum.STOCK,
                label: 'Tempo em stock',
                ratio: 1 / 10,
                renderFunction: vehicle => {
                    const daysInStock = AdminUtils.getDaysInStock(
                        vehicle.createdAt
                    )
                    return getFormatedComponentFromDays(daysInStock)
                },
                onClick: onHeaderClick,
            },
            {
                key: 'dateVehicle',
                label: 'Data de registo',
                ratio: 1.5 / 10,
                renderFunction: (vehicle: Vehicle) =>
                    AdminUtils.getVehicleMonthAndDate(vehicle),
                onClick: onHeaderClick,
                alignment: 'center',
            },
            {
                key: 'fuel',
                label: 'Combustível',
                ratio: 1 / 10,
                renderFunction: (vehicle: Vehicle) =>
                    TranslationUtils.getFuelTypeTranslation(vehicle.fuel),
            },
            {
                key: 'typeVehicle',
                label: 'Tipo de veículo',
                ratio: 1 / 10,
                renderFunction: (vehicle: Vehicle) =>
                    TranslationUtils.getVehicleTypeTranslation(
                        vehicle.typeVehicle
                    ),
            },
            {
                key: HeaderEnum.BUY_PRICE,
                label: 'Preço de compra + custos',
                ratio: 1.5 / 10,
                visibility: VisibilityStatusEnum.ADMIN_ONLY,
                renderFunction: (vehicle: Vehicle) =>
                    euroFormatter.format(
                        vehicle.buyPrice +
                            (vehicle.totalCosts ? vehicle.totalCosts : 0)
                    ),
                onClick: onHeaderClick,
                alignment: 'center',
            },
            {
                key: HeaderEnum.MILEAGE,
                label: 'Quilometragem',
                ratio: 1.5 / 10,
                visibility: VisibilityStatusEnum.SELLER_ONLY,
                onClick: onHeaderClick,
                alignment: 'center',
                renderFunction: (vehicle: Vehicle) => {
                    if (vehicle.mileage) {
                        return (
                            new Intl.NumberFormat('pt-PT', {
                                maximumSignificantDigits: 3,
                            }).format(vehicle.mileage) + ' km'
                        )
                    } else {
                        return '-'
                    }
                },
            },
            {
                key: HeaderEnum.SELLER_PRICE,
                label: 'Preço de tabela',
                ratio: 1 / 10,
                renderFunction: (vehicle: Vehicle) =>
                    euroFormatter.format(vehicle.sellerPrice),
                onClick: onHeaderClick,
                alignment: 'flex-end',
            },
        ]
    }

    const editVehicle = (vehicle: Vehicle) => {
        navigate(RoutesEnum.VEHICLE_PAGE.replace(':vehicleId', vehicle.uuid))
    }

    const downloadCSVStock = async (companyUuid: string, companyName: string) =>
        new Promise<Blob>(async () => {
            try {
                const response = await AdminApi.methods.getCSVStock(companyUuid)

                alert.success('O CSV será transferido automaticamente')
                // Create a link element to download the PDF
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(response.data)

                link.download = `Stock Atual - ${companyName}`

                dispatch(AdminActions.methods.getCSVStock(response.data))

                // Append the link to the document and trigger the click event
                document.body.appendChild(link)
                link.click()

                setTimeout(() => {
                    document.body.removeChild(link)
                }, 1000)
            } catch (error) {
                console.error('Error generating CSV:', error)
            }
        })

    return (
        <>
            <Row style={{ justifyContent: 'space-between' }}>
                <PageTitle>Veículos em stock</PageTitle>
                {(isAdmin || isWorkshop) && (
                    <PdfGeneratorButton
                        icon={'/icons/csv.png'}
                        action={() =>
                            downloadCSVStock(
                                company ? company.uuid : '',
                                company ? company.name : ''
                            )
                        }
                        title={''}
                    />
                )}
            </Row>
            <VehicleSearch
                updateFilter={updateFilter}
                filters={filters}
                clearAllFilters={clearAllFilters}
                clearFilter={clearFilter}
            />
            <Table
                isLoadingData={isFetchingVehicles}
                values={visibleVehicles}
                onRowClick={editVehicle}
                columns={getColumns()}
                standExistOnly={standExistOnly}
                vehiclesExists={vehiclesExists}
                getUrl={value =>
                    RoutesEnum.VEHICLE_PAGE.replace(':vehicleId', value.uuid)
                }
            />
            {standsExists ? (
                <ActionButton onClick={navigateToCreateVehicle} />
            ) : null}
        </>
    )
}

export default AdminDashboardPage
