import React from 'react'
import { IBaseInterface } from './vehicle.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import PublicFeatures from '../components/public-features.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { PrimaryPhoto } from '../components/primary-photo.component'
import MotorSection from '../components/motor-section.component'
import { MediaSection } from '../components/media-section.component'
import ManagementSection from '../components/management-section.component'
import { CostsSection } from '../components/costs-section.component'
import CreateVehicleExtras from '../components/create-vehicle-extras.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import produce from 'immer'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { navigate } from 'hookrouter'
import { VehicleRoutesEnum } from '../../routes.constants'
import { CreateVehicleRequest } from '../admin.interfaces'
import OnlineSection from '../components/online-section.component'
import {
    useIsEditor1_2,
    useIsEditor3,
} from '../../shared/hooks/use-is-editor.hook'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'
import { SupplierSection } from '../components/supplier-section.component'
import { useIsSeller } from '../../shared/hooks/use-is-seller.hook'
import { createVehicleSchema } from '../validation.schemas'
import PromotionSection from '../components/promotion-section.component'

export const VehicleDetailsPage = ({ vehicle }: IBaseInterface) => {
    // Hooks
    const isAdmin = useIsAdmin()
    const isEditor1_2 = useIsEditor1_2()
    const isEditor3 = useIsEditor3()
    const isWorkshop = useIsWorkshop()
    const isSeller = useIsSeller()
    const dispatch = useDispatch()
    const alert = useAlert()

    const [isSupplierEditing, setIsSupplierEditing] = React.useState<boolean>(
        false
    )
    const [isSupplierCreated, setIsSupplierCreated] = React.useState<boolean>(
        false
    )
    const [isFrequent, setIsFrequent] = React.useState<boolean | undefined>(
        undefined
    )

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)
                    setIsSupplierEditing(false)
                    setIsFrequent(undefined)
                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
            setIsSupplierEditing(false)
            setIsFrequent(undefined)
        }
    }

    /**
     * Calls the API endpoint to update a vehicle and handles the
     * loading and error states of the form
     */
    async function updateVehicle(
        values: CreateVehicleRequest,
        actions: FormikHelpers<CreateVehicleRequest>
    ) {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.updateVehicle(
                vehicle.uuid,
                values
            )
            dispatch(
                AdminActions.methods.updateVehicleSuccessAction(
                    res.data.vehicle
                )
            )
            setIsEditing(false)
            setIsSupplierEditing(false)
            alert.success('Veículo gravado com successo')
            setIsSupplierCreated(false)
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o veículo. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    /**
     * Navigate to the SaleDetails page
     * This is called when the vehicle has a sale object
     */
    function navigateToSalesDetails() {
        navigate(
            VehicleRoutesEnum.SALE_DETAILS.replace(':vehicleId', vehicle.uuid)
        )
    }
    /**
     * Navigate to the CreateSale page.
     * This is only called if the vehicle has no sale object
     */
    function navigateToCreateSale() {
        navigate(
            VehicleRoutesEnum.CREATE_SALE.replace(':vehicleId', vehicle.uuid)
        )
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...vehicle,
            vehicleExtras: vehicle.vehicleExtras.map(v => v.description),
        }
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit And Sell vehicle buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                        disabled={isEditing && isSupplierEditing && !isFrequent}
                    />
                </>
            )
        }
        return (
            <>
                <PrimaryButton
                    label="Editar veículo"
                    onClick={() => setIsEditing(true)}
                    redTheme={true}
                />
                {!isWorkshop && (
                    <PrimaryButton
                        label={
                            vehicle.sale
                                ? 'Detalhes de venda'
                                : 'Vender veículo'
                        }
                        onClick={
                            vehicle.sale
                                ? navigateToSalesDetails
                                : navigateToCreateSale
                        }
                        goldTheme={vehicle.sale ? false : true}
                        greenTheme={vehicle.sale ? true : false}
                    />
                )}
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateVehicleRequest>
                initialValues={getInitialValues()}
                onSubmit={updateVehicle}
                validationSchema={createVehicleSchema()}
            >
                {({ values, setFieldValue, dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={`Detalhes do ${vehicle.brand} ${vehicle.model}`}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>
                        <PublicFeatures
                            isEditing={
                                (isAdmin || isEditor3 || isWorkshop) &&
                                isEditing
                            }
                            rightElement={
                                <PrimaryPhoto
                                    photo={values.photos?.find(
                                        photo => photo.isFeatured
                                    )}
                                />
                            }
                        />

                        <MotorSection
                            isEditing={
                                (isAdmin || isEditor3 || isWorkshop) &&
                                isEditing
                            }
                        />

                        <ManagementSection isEditing={isEditing} />

                        {isAdmin || isWorkshop ? (
                            <CostsSection isEditing={isEditing} />
                        ) : null}

                        <CreateVehicleExtras
                            isEditing={
                                (isAdmin ||
                                    isEditor1_2 ||
                                    isEditor3 ||
                                    isWorkshop) &&
                                isEditing
                            }
                            handleChange={(value: string) => {
                                setFieldValue(
                                    'vehicleExtras',
                                    produce<string[]>(
                                        values.vehicleExtras,
                                        draftState => {
                                            const extraIdx = draftState.findIndex(
                                                s => s === value
                                            )
                                            if (extraIdx > -1) {
                                                draftState.splice(extraIdx, 1)
                                            } else {
                                                draftState.push(value)
                                            }
                                        }
                                    )
                                )
                            }}
                            selectedExtras={values.vehicleExtras}
                        />

                        {(isAdmin || isWorkshop) && (
                            <SupplierSection isEditing={isEditing} />
                        )}

                        <MediaSection
                            isEditing={
                                (isAdmin ||
                                    isEditor1_2 ||
                                    isEditor3 ||
                                    isWorkshop) &&
                                isEditing
                            }
                            vehicleId={vehicle.uuid}
                        />

                        <OnlineSection isEditing={isEditing} />

                        <PromotionSection isEditing={isEditing} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
