import React from 'react'
import { Column, Row, RatioContainer } from '../../shared/shared.styled'
import SmallButton from '../../shared/components/small-button.component'
import PlusIcon from '../../assets/icons/plus.icon'
import { Colors, Typography } from '../../configs/styled.config'
import { AddFinancialModal } from './modals/add-financial.modal'
import { useModal } from 'react-modal-hook'
import styled from 'styled-components'
import { CompanyFinancial, Financial } from '../../shared/shared.models'
import { FinancialsTable } from './financials-table.component'

export interface SelectedFinancial {
    financial: Financial
    financialIdx: number
}

interface FinancialsSectionProps {
    isEditing?: boolean
    values?: CompanyFinancial[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export const FinancialsSection = ({
    isEditing = true,
    values = [],
    setFieldValue,
}: FinancialsSectionProps) => {
    const [selectedFinancial, setSelectedFinancial] = React.useState<
        SelectedFinancial | undefined
    >(undefined)

    const [showModal, hideModal] = useModal(
        () => (
            <AddFinancialModal
                selectedFinancial={selectedFinancial}
                hideModal={hideModal}
                addFinancial={addFinancial}
                editFinancial={editFinancial}
            />
        ),
        [selectedFinancial]
    )

    /**
     * Adds a Financial to our formik values
     * @param values {Cost}
     */
    const addFinancial = (newValue: Financial) => {
        const previousFinancials = values || []
        previousFinancials.push(newValue)

        setFieldValue('financials', previousFinancials)
        hideModal()
    }

    /**
     * Deletes a Financial from our formik values
     * @param financialIdx {number}
     */
    const deleteFinancial = (financialIdx: number) => {
        const financialToDelete = values[financialIdx]
        setFieldValue(
            'financials',
            values.filter(fin => fin !== financialToDelete)
        )
    }

    /**
     * Replaces a cost with the new values
     * @param obj SelectedFinancial
     */
    const editFinancial = (obj: SelectedFinancial) => {
        const previousFinancials = values
        if (previousFinancials) {
            previousFinancials.splice(obj.financialIdx, 1, obj.financial)
            setFieldValue('financials', previousFinancials)
        }
        setSelectedFinancial(undefined)
        hideModal()
    }

    const showEditFinancialModal = (financialIdx: number) => {
        if (values) {
            const financial = values[financialIdx]
            setSelectedFinancial({ financial, financialIdx })
            showModal()
        }
    }

    return (
        <SectionFinancials isEditing={isEditing}>
            <SectionTitle>
                <Row>
                    <StyledTitle isRequired={isEditing}>
                        Financeiras
                    </StyledTitle>
                    {isEditing ? (
                        <Row style={{ marginLeft: 'auto' }}>
                            <SmallButton
                                disabled={!isEditing}
                                iconComponent={
                                    <PlusIcon
                                        style={{
                                            fill: isEditing
                                                ? Colors['gold-pmauto']
                                                : '#d8d8d8',
                                        }}
                                    />
                                }
                                label="Adicionar nova financeira"
                                onClick={() => showModal()}
                            />
                        </Row>
                    ) : null}
                </Row>
            </SectionTitle>
            <Column style={{ marginTop: 16 }}>
                {values && values.length > 0 ? (
                    <FinancialsTable
                        editFinancial={showEditFinancialModal}
                        deleteFinancial={deleteFinancial}
                        financials={values}
                        isEditing={isEditing}
                    />
                ) : (
                    <StyledText>Não existem financeiras associadas.</StyledText>
                )}
            </Column>
            <Row style={{ marginTop: 24, justifyContent: 'flex-end' }}>
                <RatioContainer ratio={1 / 8}></RatioContainer>
            </Row>
        </SectionFinancials>
    )
}

const StyledText = styled('span')`
    align-self: center;
    ${Typography.H1_Regular_Center};
    font-size: 14px;
`

// const TotalText = styled('span')`
//     ${Typography['Body_Text_#1_High_Contrast_Right']};
//     margin-right: 8px;
// `

// const ValueText = styled('span')`
//     ${Typography['Body_Text_#1_Regular_Left']};
// `

export const StyledTitle = styled('span')<{ isRequired: boolean }>`
    font-weight: bold;
    margin-bottom: 8px;
    ${Typography['Body_Text_#1_Regular_Left']};
    font-size: 18px;
    color: #44566c;

    ${props =>
        props.isRequired
            ? `
   ::after {
     margin-left: 2px;
     content: '*';
     color: ${Colors['gold-pmauto']};
   }
   `
            : ''}
`

export const SectionTitle = styled('h2')`
    ${Typography['H3_Regular_Left']};
    font-size: 24px;
    margin-top: 0px !important;
`

export const SectionFinancials = styled('div')<{ isEditing: boolean }>`
    width: 100%;

    ${props =>
        props.isEditing
            ? `
        max-height: 500px;
        overflow: auto;
     `
            : ' max-height: 250px;overflow: auto;'}
`
